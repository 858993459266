import React from "react"
import { ProjectLayout } from "../../components/common/ProjectsLayout"
import { useStaticQuery, graphql } from "gatsby"
import { PROJECT_PERSONAL_SEO } from "../../config/siteMetaData"

const PersonalPage = () => {
  const data = useStaticQuery(graphql`
    query {
      personalProject: file(relativePath: { eq: "luck.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <ProjectLayout
      image={data.personalProject.childImageSharp.fluid}
      header="hecben.dev"
      intro="
        This portfolio was an accumulation of random ideas I had when creating my first portfolio a year ago.
      I initially wanted a sleak clean look, but still with enough character to represent me. 
      This was my 'best' attempt."
      body="
        My portfolio was a glimpse of designing a project from the beginning and developing it to production. 
        Every page, image and svg were made on Figma. Once the design/layout was finalized I transferred over to Zeplin. 
        The site was generated via Gatsby.js, with PostCSS, CSS modules and Tailwind.css configuration.
        The site is being hosted by Netlify, because I have grown to admire their UI and UX (and all other extensions capabilities).
      "
      conclusion="
        I wasnt sure I'd be able to develop this portfolio each day I worked in Figma. I hadn't worked with React or any modern CSS tech since my bootcamp.
      With the help of Nick and my fiancé, I was able to produce what you are 'hopefully' looking at today.
      Hope whoever is reading this enjoyed and if you read this far, know that I appreciate you. Lets get tacos soon!
      "
      tech="React.js, Gatsby.js, PostCSS, CSS Modules, Tailwind.css, Netlify, Figma"
      {...PROJECT_PERSONAL_SEO}
    />
  )
}

export default PersonalPage
